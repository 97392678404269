<template>
  <Header title="OneDayPicnic" />
  <!-- BODY SECTION START -->
  <!----- SLIDER SECTION ----->
  <section class="home_slider_container">
    <div class="home_slider_section margin_top">
      <div class="home_slider_row overflow_hidden">
        <div class="home_slider_col">
          <div class="home_slider_block">
            <div class="home_main_slider1">
              <div class="home_main_slider">
                <div class="main_slider_img_block">
                  <img :src="banner1" class="main_slider_img" />
                  <div class="slider_content_block">
                    <div class="inner_banner_block">
                      <p class="body_heading tc">One Day Picnic</p>
                    </div>
                  </div>
                </div>
                <div class="main_slider_img_block">
                  <img :src="banner2" class="main_slider_img" />
                  <div class="slider_content_block">
                    <div class="inner_banner_block">
                      <p class="body_heading tc">One Day Picnic</p>
                    </div>
                  </div>
                </div>
                <div class="main_slider_img_block">
                  <img :src="banner3" class="main_slider_img" />
                  <div class="slider_content_block">
                    <div class="inner_banner_block">
                      <p class="body_heading tc">One Day Picnic</p>
                    </div>
                  </div>
                </div>
                <div class="main_slider_img_block">
                  <img :src="banner4" class="main_slider_img" />
                  <div class="slider_content_block">
                    <div class="inner_banner_block">
                      <p class="body_heading tc">One Day Picnic</p>
                    </div>
                  </div>
                </div>
                <div class="main_slider_img_block">
                  <img :src="banner5" class="main_slider_img" />
                  <div class="slider_content_block">
                    <div class="inner_banner_block">
                      <p class="body_heading tc">One Day Picnic</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div thumbsSlider="" class="slider_thumb_outter">
              <div class="slider_thumb_inner">
                <div class="slider_thumb_img_slider">
                  <div class="slider_thumb_img_block">
                    <img :src="banner1" class="slider_thumb_img" />
                  </div>
                </div>
                <div class="slider_thumb_img_slider">
                  <div class="slider_thumb_img_block">
                    <img :src="banner2" class="slider_thumb_img" />
                  </div>
                </div>
                <div class="slider_thumb_img_slider">
                  <div class="slider_thumb_img_block">
                    <img :src="banner3" class="slider_thumb_img" />
                  </div>
                </div>
                <div class="slider_thumb_img_slider">
                  <div class="slider_thumb_img_block">
                    <img :src="banner4" class="slider_thumb_img" />
                  </div>
                </div>
                <div class="slider_thumb_img_slider">
                  <div class="slider_thumb_img_block">
                    <img :src="banner5" class="slider_thumb_img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ----------- data & Explore the Villa & Amenities ----------- -->
  <section class="villa_data_section row_padding lef_img">
    <div class="villa_data_row main_container">
      <div class="villa_data_col villa_left_col">
        <div class="villa_left_block">
          <div class="body_heading_block desk_heading">
            <p class="body_heading">Welcome to our slice of paradise!</p>
            <div class="small_body_font">
              <p>
                Rio Colina offers a peaceful and serene environment for a one
                day picnic plan with your loved ones! The tranquil and peaceful
                environment ensures that you get the relaxation and comfort that
                you have been looking for. Nestled amidst lush greenery with a
                view overlooking the tapi river, this place is perfect for a
                maddening crowd away from the hustle and bustle of everyday
                life.
              </p>
              <p>
                Located just 60 mins away from Sun city, it is the perfect
                getaway destination to relax, unwind, rejuvenate, and soothe
                your spirits. Our wide range of facilities and services are
                curated as per your convenience and pleasure to ensure that you
                enjoy a comfortable stay with us.
              </p>
              <p>
                Step into a world of luxury, convenience, and comfort to enjoy a
                perfect one day picnic with your family! 🙂
              </p>
            </div>
            <div class="bird bird--one">
              <div class="vill_btn">
                <a
                  href="https://www.youtube.com/watch?v=0piTRDF-w0g&embeds_referring_euri=https%3A%2F%2Friocolina.com%2F&source_ve_path=Mjg2NjMsMjg2NjY&feature=emb_logo"
                  class="site_button"
                  target="_blank"
                >
                  <div class="site_button_div">
                    <span class="btn_text">Take a tour of rio</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="villa_data_col villa_right_col" id="wrapper_box">
        <div class="villa_right_block" id="sticky_box">
          <div class="wedding_right_box">
            <div class="tab">
              <button
                class="tablinks = box_title_text"
                @click="selectPackage(item, index)"
                v-for="(item, index) in packages"
                :key="index"
                :class="{ week_tab_active: item.active }"
              >
                {{ item.pack_name }}
              </button>
            </div>
            <div
              id="weekendtab"
              class="tabcontent"
              v-for="item in packages"
              :class="{ main_active: item.active }"
              :key="item.id"
            >
              <div class="tab_content_outter">
                <div class="tab_content_inner">
                  <div class="tab_content_block">
                    <div class="price_cal_box padding_box">
                      <div class="price_cal_innr_box package_tab">
                        <p class="price_cal_title font_20">Price For Adult</p>
                        <div class="price_cal_num body_heading">
                          ₹ {{ parseFloat(item.adult_price).toFixed(2) }}
                          <p class="price_cal_title">18% GST INC.</p>
                        </div>
                      </div>
                      <div class="price_cal_innr_box package_tab">
                        <p class="price_cal_title font_20">
                          Price For Children
                        </p>
                        <div class="price_cal_num body_heading">
                          ₹ {{ parseFloat(item.child_price).toFixed(2) }}
                          <p class="price_cal_title">18% GST INC.</p>
                        </div>
                      </div>
                    </div>
                    <div class="separater"></div>
                    <div class="second_box padding_box">
                      <div class="amount_box">
                        <p class="font_16">
                          Check-in time: <span>{{ item.check_in_time }}</span>
                        </p>
                        <p class="font_16">
                          Check-out time: <span>{{ item.check_out_time }}</span>
                        </p>
                      </div>
                      <div class="btn_block btn_center_block">
                        <div class="btn_border block_cta">
                          <router-link
                            class="site_button"
                            :to="{
                              name: 'one-day-picnic-booking',
                              params: {
                                package_id: item.id,
                              },
                            }"
                          >
                            <div class="site_button_div">
                              <span class="font_20">Book Now</span>
                            </div>
                          </router-link>
                          <a class="site_button">
                            <div class="site_button_div">
                              <router-link to="/contact-us"
                                ><span class="font_20"
                                  >Send Inquiry</span
                                ></router-link
                              >
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ---------- PACKAGES SECTION ---------- -->
  <section class="package_section row_padding pdb_0">
    <div class="package_row">
      <div class="package_col">
        <div class="body_heading_block">
          <p class="body_heading tc">Packages</p>
        </div>
        <!-- tabing start -->
        <div class="tab_package_outter">
          <div class="tab_package_inner">
            <!-- tab button -->
            <ul class="packages_top_outter">
              <li
                class="packages_top_inner packages_top_inner_2"
                @click="selectPackage2(item, index)"
                v-for="(item, index) in packages"
                :key="index"
                :class="{ packages_top_inner_active: item.active_bottom }"
              >
                <div class="packages_inn_box">
                  <p class="packages_top_title font_20 site_button">
                    {{ item.pack_name }}
                  </p>
                </div>
              </li>
            </ul>
            <!-- end -->
            <!-- tab content -->
            <div class="packages_bottom_outter">
              <div
                class="packages_bottom_inner packages_bottom_inner_2"
                v-for="(item, index) in packages"
                :key="index"
                :class="{ packages_bottom_inner_active: item.active_bottom }"
              >
                <div
                  class="package_outter__block package_outter_bgcss row_padding"
                >
                  <div class="package_inner_block main_container">
                    <div class="package_block">
                      <div class="package_img_block">
                        <img :src="item.package_image" class="package_img" />
                      </div>
                      <div class="package_content_block">
                        <div class="package_content_outter">
                          <div class="package_content_top_block">
                            <div class="package_content_inner">
                              <p class="package_content_head font_30">
                                {{ item.pack_name }}
                              </p>
                              <div class="price_box_out">
                                <div class="price_box_inn">
                                  <div class="price_main_box">
                                    <div class="price_main_white_box">
                                      <p class="box_title_text">
                                        Price For Adults
                                      </p>
                                      <div class="package_price_box">
                                        <div class="data_sub_heading price">
                                          ₹
                                          {{
                                            parseFloat(
                                              item.adult_price
                                            ).toFixed(2)
                                          }}
                                          <p class="data_sub_heading">
                                            18% GST INC.
                                          </p>
                                        </div>
                                      </div>
                                      <div class="package_btn_block btn_block">
                                        <div class="btn_border block_cta">
                                          <div class="bird bird--one">
                                            <a href="#" class="site_button">
                                              <div class="site_button_div">
                                                <router-link
                                                  class="btn_text"
                                                  :to="{
                                                    name: 'one-day-picnic-booking',
                                                    params: {
                                                      package_id: item.id,
                                                    },
                                                  }"
                                                  >Book Now</router-link
                                                >
                                              </div>
                                            </a>
                                          </div>
                                          <!-- <span>or</span> -->
                                          <div class="bird bird--one">
                                            <a class="site_button">
                                              <div class="site_button_div">
                                                <router-link to="/contact-us">
                                                  <span class="btn_text"
                                                    >Inquiry</span
                                                  >
                                                </router-link>
                                              </div>
                                            </a>
                                          </div>
                                          <!-- <span class="btn_text">Inquiry</span> -->
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="price_main_box">
                                    <div class="price_main_white_box">
                                      <p class="box_title_text">
                                        Price For Children
                                      </p>
                                      <div class="package_price_box">
                                        <div class="data_sub_heading price">
                                          ₹
                                          {{
                                            parseFloat(
                                              item.child_price
                                            ).toFixed(2)
                                          }}
                                          <p class="data_sub_heading">
                                            18% GST INC.
                                          </p>
                                        </div>
                                      </div>
                                      <div class="package_btn_block btn_block">
                                        <div class="btn_border block_cta">
                                          <div class="bird bird--one">
                                            <a href="#" class="site_button">
                                              <div class="site_button_div">
                                                <router-link
                                                  class="btn_text"
                                                  :to="{
                                                    name: 'one-day-picnic-booking',
                                                    params: {
                                                      package_id: item.id,
                                                    },
                                                  }"
                                                  >Book Now</router-link
                                                >
                                              </div>
                                            </a>
                                          </div>
                                          <div class="bird bird--one">
                                            <a href="#" class="site_button">
                                              <div class="site_button_div">
                                                <router-link to="/contact-us">
                                                  <span class="btn_text"
                                                    >Inquiry</span
                                                  >
                                                </router-link>
                                              </div>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="package_content_bottom_block">
                            <div class="package_content_inner">
                              <p class="package_content_head font_30">
                                Inclusion
                              </p>
                              <div class="package_point_outter">
                                <div class="package_faqs_inner">
                                  <img :src="p_01" class="package_inner_img" />
                                  <p class="font_16 package_inner_title tc">
                                    Food
                                  </p>
                                </div>
                                <div class="package_faqs_inner">
                                  <img :src="p_02" class="package_inner_img" />
                                  <p class="font_16 package_inner_title tc">
                                    Sports
                                  </p>
                                </div>
                                <div class="package_faqs_inner">
                                  <img :src="p_03" class="package_inner_img" />
                                  <p class="font_16 package_inner_title tc">
                                    Swimming Pool
                                  </p>
                                </div>
                                <div class="package_faqs_inner">
                                  <img
                                    :src="activities_04"
                                    class="package_inner_img"
                                  />
                                  <p class="font_16 package_inner_title tc">
                                    Children's Water Park
                                  </p>
                                </div>
                                <div class="package_faqs_inner">
                                  <img :src="candy" class="package_inner_img" />
                                  <p class="font_16 package_inner_title tc">
                                    Children's Candy House
                                  </p>
                                </div>
                                <div class="package_faqs_inner">
                                  <img :src="p_05" class="package_inner_img" />
                                  <p class="font_16 package_inner_title tc">
                                    Movie Theater
                                  </p>
                                </div>
                                <!-- <div class="package_faqs_inner">
                                  <img :src="p_04" class="package_inner_img" />
                                  <p class="font_16 package_inner_title tc">
                                    DiscoTheque
                                  </p>
                                </div> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="verti_tab_section row_padding pdb_0">
                  <div class="ab_big_leaf_section long_l_leaf long_r_leaf">
                    <div class="verti_tab_row main_container verti_tab_row_01">
                      <div class="verti_tab_col">
                        <div class="body_heading_block point_data">
                          <p class="font_30">Inclusion</p>
                          <p class="font_40">{{ item.pack_name }}</p>
                        </div>
                        <div class="vtab_brand_block">
                          <div class="vtab_brand_inner_block">
                            <div class="vtab_brand_box_right">
                              <div class="vtab_brand_pro_name">
                                <ul>
                                  <li
                                    class="vtab_brand_li title_01"
                                    data-id="01"
                                    v-on:click="foods"
                                    v-bind:class="[
                                      caT_01 == true ? 'vtab_active' : '',
                                    ]"
                                  >
                                    <div class="font_20">
                                      <span>Foods</span>
                                    </div>
                                  </li>
                                  <li
                                    class="vtab_brand_li title_02"
                                    data-id="02"
                                    v-on:click="sports"
                                    v-bind:class="[
                                      caT_02 == true ? 'vtab_active' : '',
                                    ]"
                                  >
                                    <div class="font_20">
                                      <span>Sports</span>
                                    </div>
                                  </li>
                                  <li
                                    class="vtab_brand_li title_03"
                                    data-id="03"
                                    v-on:click="swimmingPool"
                                    v-bind:class="[
                                      caT_03 == true ? 'vtab_active' : '',
                                    ]"
                                  >
                                    <div class="font_20">
                                      <span>Swimming Pool</span>
                                    </div>
                                  </li>
                                  <li
                                    class="vtab_brand_li title_04"
                                    data-id="04"
                                    v-on:click="childrenWP"
                                    v-bind:class="[
                                      caT_04 == true ? 'vtab_active' : '',
                                    ]"
                                  >
                                    <div class="font_20">
                                      <span>Children's water park</span>
                                    </div>
                                  </li>
                                  <li
                                    class="vtab_brand_li title_05"
                                    data-id="05"
                                    v-on:click="childrenCH"
                                    v-bind:class="[
                                      caT_05 == true ? 'vtab_active' : '',
                                    ]"
                                  >
                                    <div class="font_20">
                                      <span>Children's candy house</span>
                                    </div>
                                  </li>
                                  <li
                                    class="vtab_brand_li title_06"
                                    data-id="06"
                                    v-on:click="movieTheater"
                                    v-bind:class="[
                                      caT_06 == true ? 'vtab_active' : '',
                                    ]"
                                  >
                                    <div class="font_20">
                                      <span>Movie theater</span>
                                    </div>
                                  </li>
                                  <!-- <li
                                    class="vtab_brand_li title_07"
                                    data-id="07"
                                    v-on:click="discoTheque"
                                    v-bind:class="[
                                      caT_07 == true ? 'vtab_active' : '',
                                    ]"
                                  >
                                    <div class="font_20">
                                      <span>DiscoTheque</span>
                                    </div>
                                  </li> -->
                                </ul>
                              </div>
                            </div>
                            <div class="vtab_brand_box_left">
                              <div
                                class="tab_content_outter tab_content animate__animated animate__zoomIn count_1 caT_01"
                                v-show="caT_01"
                                v-bind:class="[
                                  caT_01 == true ? 'show_block' : '',
                                ]"
                              >
                                <div class="faq_wrapper" v-if="item.id == 1">
                                  <div class="faq_container">
                                    <div class="question">
                                      <img :src="Breakfast" alt="" />
                                      <p class="body_font">Breakfast</p>
                                    </div>
                                    <div class="answercont">
                                      <div class="body_font answer">
                                        <div class="faqs_inner_outter">
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="faq_tab_01"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Alloo Paratha
                                            </p>
                                          </div>
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="faq_tab_02"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Veg Sandwich
                                            </p>
                                          </div>
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="faq_tab_03"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Bread butter jam
                                            </p>
                                          </div>
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="faq_tab_04"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Cookies
                                            </p>
                                          </div>
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="toast"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Toast
                                            </p>
                                          </div>
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="tea_and_coffee"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Tea & Coffee
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="faq_container">
                                    <div class="question">
                                      <img :src="faq_tab_04" alt="" />
                                      <p class="body_font">Lunch</p>
                                    </div>
                                    <div class="answercont">
                                      <div class="body_font answer">
                                        <div class="faqs_inner_outter">
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="soup"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Soup
                                            </p>
                                          </div>
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="starter"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Starter
                                            </p>
                                          </div>
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="main_course"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Main Course
                                            </p>
                                          </div>
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="dessert"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Dessert
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="faq_container">
                                    <div class="question">
                                      <img :src="soup" alt="" />
                                      <p class="body_font">Hi Tea</p>
                                    </div>
                                    <div class="answercont">
                                      <div class="body_font answer">
                                        <div class="faqs_inner_outter">
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="cocktail_samosa"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Cocktail Samosa
                                            </p>
                                          </div>
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="faq_tab_02"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Veg Sandwich
                                            </p>
                                          </div>
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="faq_tab_04"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Cookies
                                            </p>
                                          </div>
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="tea_and_coffee"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Tea & Coffee
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="faq_container">
                                    <div class="question">
                                      <img :src="faq_tab_04" alt="" />
                                      <p class="body_font">Dinner</p>
                                    </div>
                                    <div class="answercont">
                                      <div class="body_font answer">
                                        <div class="faqs_inner_outter">
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="soup"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Soup
                                            </p>
                                          </div>
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="starter"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Starter
                                            </p>
                                          </div>
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="main_course"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Main Course
                                            </p>
                                          </div>
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="dessert"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Dessert
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="faq_wrapper" v-if="item.id == 2">
                                  <div class="faq_container">
                                    <div class="question">
                                      <img :src="Breakfast" alt="" />
                                      <p class="body_font">Breakfast</p>
                                    </div>
                                    <div class="answercont">
                                      <div class="body_font answer">
                                        <div class="faqs_inner_outter">
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="faq_tab_01"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Alloo Paratha
                                            </p>
                                          </div>
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="faq_tab_02"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Veg Sandwich
                                            </p>
                                          </div>
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="faq_tab_03"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Bread butter jam
                                            </p>
                                          </div>
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="faq_tab_04"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Cookies
                                            </p>
                                          </div>
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="toast"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Toast
                                            </p>
                                          </div>
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="tea_and_coffee"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Tea & Coffee
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="faq_container">
                                    <div class="question">
                                      <img :src="faq_tab_04" alt="" />
                                      <p class="body_font">Lunch</p>
                                    </div>
                                    <div class="answercont">
                                      <div class="body_font answer">
                                        <div class="faqs_inner_outter">
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="soup"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Soup
                                            </p>
                                          </div>
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="starter"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Starter
                                            </p>
                                          </div>
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="main_course"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Main Course
                                            </p>
                                          </div>
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="dessert"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Dessert
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="faq_container">
                                    <div class="question">
                                      <img :src="soup" alt="" />
                                      <p class="body_font">Hi Tea</p>
                                    </div>
                                    <div class="answercont">
                                      <div class="body_font answer">
                                        <div class="faqs_inner_outter">
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="cocktail_samosa"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Cocktail Samosa
                                            </p>
                                          </div>
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="faq_tab_02"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Veg Sandwich
                                            </p>
                                          </div>
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="faq_tab_04"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Cookies
                                            </p>
                                          </div>
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="tea_and_coffee"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Tea & Coffee
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="faq_container">
                                    <div class="question">
                                      <img :src="faq_tab_04" alt="" />
                                      <p class="body_font">Dinner</p>
                                    </div>
                                    <div class="answercont">
                                      <div class="body_font answer">
                                        <div class="faqs_inner_outter">
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="soup"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Soup
                                            </p>
                                          </div>
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="starter"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Starter
                                            </p>
                                          </div>
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="main_course"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Main Course
                                            </p>
                                          </div>
                                          <div class="faqs_inner_inner">
                                            <img
                                              :src="dessert"
                                              class="faqs_inner_img"
                                            />
                                            <p
                                              class="body_font faqs_inner_title"
                                            >
                                              Dessert
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="tab_content_outter tab_content animate__animated animate__zoomIn count_2 caT_02"
                                v-show="caT_02"
                                v-bind:class="[
                                  caT_02 == true ? 'show_block' : '',
                                ]"
                              >
                                <div class="tab_content_inner">
                                  <div class="tab_content_img_block">
                                    <img
                                      :src="o_sports"
                                      class="tab_content_img"
                                    />
                                  </div>
                                  <div class="tab_content_block">
                                    <p class="small_body_font">
                                      We offer a range of activities to help you
                                      enjoy your leisure time. Our facilities
                                      include options for sports enthusiasts
                                      which they can enjoy at their own leisure.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="tab_content_outter tab_content animate__animated animate__zoomIn count_3 caT_03"
                                v-show="caT_03"
                                v-bind:class="[
                                  caT_03 == true ? 'show_block' : '',
                                ]"
                              >
                                <div class="tab_content_inner">
                                  <div class="tab_content_img_block">
                                    <img
                                      :src="o_swimming_pool"
                                      class="tab_content_img"
                                    />
                                  </div>
                                  <div class="tab_content_block">
                                    <p class="small_body_font">
                                      Unwind and relax as you take a dip in our
                                      luxurious swimming pool equipped with fun
                                      activities and amusements for your kids to
                                      enjoy! Timings are from 9:00 am to 7:00
                                      pm.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="tab_content_outter tab_content animate__animated animate__zoomIn count_4 caT_04"
                                v-show="caT_04"
                                v-bind:class="[
                                  caT_04 == true ? 'show_block' : '',
                                ]"
                              >
                                <div class="tab_content_inner">
                                  <div class="tab_content_img_block">
                                    <img
                                      :src="o_childerns_waterpark"
                                      class="tab_content_img"
                                    />
                                  </div>
                                  <div class="tab_content_block">
                                    <p class="small_body_font">
                                      This is the place where imagination meets
                                      fun, and your kids can experience an
                                      amazing adventure while staying cool on a
                                      hot summer day. The water park will start
                                      after a minimum of 10 children are ready
                                      to make a splash together for 2 hours in
                                      the morning and evening.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="tab_content_outter tab_content animate__animated animate__zoomIn count_5 caT_05"
                                v-show="caT_05"
                                v-bind:class="[
                                  caT_05 == true ? 'show_block' : '',
                                ]"
                              >
                                <div class="tab_content_inner">
                                  <div class="tab_content_img_block">
                                    <img
                                      :src="o_childrens_candy_house"
                                      class="tab_content_img"
                                    />
                                  </div>
                                  <div class="tab_content_block">
                                    <p class="small_body_font">
                                      Welcome to the magical candy house! Our
                                      doors are open and we're ready to welcome
                                      children between the ages of 1 to 5 years
                                      old to join us for a sweet adventure
                                      between 9:00 am to 6:30 pm.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="tab_content_outter tab_content animate__animated animate__zoomIn count_6 caT_06"
                                v-show="caT_06"
                                v-bind:class="[
                                  caT_06 == true ? 'show_block' : '',
                                ]"
                              >
                                <div class="tab_content_inner">
                                  <div class="tab_content_img_block">
                                    <img
                                      :src="o_movie_theater"
                                      class="tab_content_img"
                                    />
                                  </div>
                                  <div class="tab_content_block">
                                    <p class="small_body_font">
                                      Enjoy popcorn and coke while feeling the
                                      aura of the reel become a reality thanks
                                      to our amazing sound system and great
                                      picture quality between 2:00 pm to 5:00 pm
                                      (the movie will start only after there are
                                      10 people). Our luxurious movie theater
                                      will enhance your movie-watching
                                      experience like never before!<br /><br />Please
                                      remember that drinking and smoking is not
                                      allowed inside the Movie Theater.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="tab_content_outter tab_content animate__animated animate__zoomIn count_7 caT_07"
                                v-show="caT_07"
                                v-bind:class="[
                                  caT_07 == true ? 'show_block' : '',
                                ]"
                              >
                                <div class="tab_content_inner">
                                  <div class="tab_content_img_block">
                                    <img
                                      :src="o_discotheque"
                                      class="tab_content_img"
                                    />
                                  </div>
                                  <div class="tab_content_block">
                                    <p class="small_body_font">
                                      Have a gala time with your family and
                                      friends at our amazing DiscoTheque
                                      (between 7:00 pm to 8:00 pm and only after
                                      there are 15 people), intricately designed
                                      for you to have the most amazing
                                      experience.
                                      <br /><br />Please remember that drinking
                                      and smoking is not allowed inside the
                                      DiscoTheque.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end -->
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- ---------- FILLER SECTION ---------- -->
  <Download />
  <!-- ---------- FACILITIES SECTION ---------- -->
  <section class="facilities_section">
    <div class="rules_row main_container">
      <div class="rules_col">
        <div class="rules_block">
          <div class="rules_left_block">
            <div class="body_heading_block">
              <p class="body_heading">Rules And Regulations</p>
            </div>
            <div class="rules_features_outter">
              <div class="rules_features_block">
                <div class="rules_outter">
                  <div class="small_body_font">
                    <ul>
                      <li>Day Picnic Hours 09:00 Am To 09:00 Pm Only</li>
                      <li>No Charge For Children Under The Age Of 3 Years</li>
                      <li>
                        Proof Of Age And Photo Id Required For All Children
                      </li>
                      <li>
                        Swimming Costume Is Compulsory. Swimming Rules And
                        Policies Apply
                      </li>
                      <li>
                        Children Bellow The Age Of 14 Years Are Not Allowed To
                        Play Paintball
                      </li>
                      <li>
                        All Meals Will Be Vegetarian As Per Chef's Choice & Will
                        Be Served At Shamiyana Restaurant
                      </li>
                      <li>Lunch Timing: 12:00pm To 02:00pm</li>
                      <li>Hi Tea Tiiming: 04:00pm To 05:00pm</li>
                      <li>
                        Additional Sporting Activities Are Also Available On
                        Chargeable Bases
                      </li>
                      <li>
                        While Departing, Takeaway Goodies Will Be Given To Each
                        Guest
                      </li>
                      <li>
                        All Special Requests Will Be Considered At Sole
                        Discretion Of The Management
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ---------- REVIEW FILLER SECTION ---------- -->
  <!-- <section class="review_section row_padding review_vector_section">
        <div class="review_row main_container">
            <div class="review_col">
                <div class="review_block">
                    <p class="body_heading tc">Reviews and Rating</p> 
                    <div class="feed_block">
                        <img :src="feedback">
                        <p class="font_20">Write a review</p>
                    </div>
                </div>
            </div>
            <div class="right_vector_outter_box">
                <div class="right_svg_move">
                    <svg width="454" height="51" viewBox="0 0 454 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M453.262 32.5001C453.262 32.5001 355.762 -5.00003 274.762 28C193.762 61.0001 149.501 58.5 9.50098 9.5" stroke="black" stroke-dasharray="2 2"/>
                        <path d="M14.5234 1.01465L1.04282 6.64648L7.57913 20.5322" stroke="black" stroke-dasharray="2 2"/>
                    </svg>
                </div>
                <img :src="right_single_title" alt="" class="right_vector_animation">
            </div>
        </div>
    </section> -->
  <Footer />
</template>
<script>
import axios from "axios";
import _ from "lodash";
import banner1 from "../assets/images/picnic_inner_1.jpg";
import banner2 from "../assets/images/picnic_inner_2.jpg";
import banner3 from "../assets/images/picnic_inner_3.jpg";
import banner4 from "../assets/images/picnic_inner_4.jpg";
import banner5 from "../assets/images/picnic_inner_5.jpg";
import filler_img from "../assets/images/filler_img.png";
// import GP_btn_01 from '../assets/images/GP_btn_01.png';
import GP_btn_01Png from "../assets/images/gp-img.png";
import AS_btn_01Png from "../assets/images/as-img.png";
import right_single_title from "../assets/images/right_single_title.png";
import soup from "../assets/images/inner_page/faq_tab_01.png";
import dessert from "../assets/images/inner_page/faq_tab_04.png";
import cocktail_samosa from "../assets/images/new_images/cocktail_samosa.png";
import faq_tab_01 from "../assets/images/new_images/alloo_paratha.png";
import Breakfast from "../assets/images/new_images/breakfast.png";
import faq_tab_02 from "../assets/images/new_images/veg_sandwich.png";
import faq_tab_03 from "../assets/images/inner_page/faq_tab_03.png";
import faq_tab_04 from "../assets/images/new_images/cookies.png";
import tea_and_coffee from "../assets/images/new_images/tea_and_coffee.png";
import toast from "../assets/images/new_images/toast.png";
import starter from "../assets/images/new_images/starter.png";
import main_course from "../assets/images/new_images/main-course.png";
import feedback from "../assets/images/inner_page/feedback.png";
import p_01 from "../assets/images/inner_page/p_01.png";
import p_02 from "../assets/images/inner_page/p_02.png";
import p_03 from "../assets/images/inner_page/p_03.png";
import p_04 from "../assets/images/inner_page/p_04.png";
import p_05 from "../assets/images/inner_page/p_05.png";
import highlights_img from "../assets/images/inner_page/highlights_img.jpg";
import packages_01 from "../assets/images/inner_page/packages_01.jpg";
import packages_02 from "../assets/images/inner_page/packages_02.jpg";
import platinum_villa from "../assets/images/inner_page/platinum_villa.jpg";
import platinum_villa_02 from "../assets/images/inner_page/platinum_villa_02.jpg";
import platinum_villa_03 from "../assets/images/inner_page/platinum_villa_03.jpg";
import activities_04 from "../assets/images/inner_page/activities_04.png";
import candy from "../assets/images/inner_page/candy.png";
import o_delightful_daycation from "../assets/images/inner_page/o-Delightful-Daycation.jpg";
import o_sports from "../assets/images/new_images/o-sports.jpg";
import o_swimming_pool from "../assets/images/new_images/o-swimming-pool.jpg";
import o_childerns_waterpark from "../assets/images/new_images/o-childerns-waterpark.jpg";
import o_childrens_candy_house from "../assets/images/new_images/o-childrens-candy-house.jpg";
import o_movie_theater from "../assets/images/new_images/o-movie-theater.jpg";
import o_discotheque from "../assets/images/new_images/o-discotheque.jpg";
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";

import Download from "@/pages/Download.vue";

export default {
  name: "OneDayPicnic",
  components: {
    Header,
    Footer,
    Download,
  },
  data() {
    return {
      foodOpen: {
        active_key: "2_Breakfast",
      },
      banner1: banner1,
      banner2: banner2,
      banner3: banner3,
      banner4: banner4,
      banner5: banner5,
      filler_img: filler_img,
      GP_btn_01Png: GP_btn_01Png,
      AS_btn_01Png: AS_btn_01Png,
      right_single_title: right_single_title,
      Breakfast: Breakfast,
      faq_tab_01: faq_tab_01,
      faq_tab_02: faq_tab_02,
      faq_tab_03: faq_tab_03,
      faq_tab_04: faq_tab_04,
      soup: soup,
      dessert: dessert,
      tea_and_coffee: tea_and_coffee,
      toast: toast,
      starter: starter,
      main_course: main_course,
      cocktail_samosa: cocktail_samosa,
      feedback: feedback,
      p_01: p_01,
      p_02: p_02,
      p_03: p_03,
      p_04: p_04,
      p_05: p_05,
      highlights_img: highlights_img,
      packages_01: packages_01,
      packages_02: packages_02,
      platinum_villa: platinum_villa,
      platinum_villa_02: platinum_villa_02,
      platinum_villa_03: platinum_villa_03,
      activities_04: activities_04,
      candy: candy,
      o_delightful_daycation: o_delightful_daycation,
      o_sports: o_sports,
      o_swimming_pool: o_swimming_pool,
      o_childerns_waterpark: o_childerns_waterpark,
      o_childrens_candy_house: o_childrens_candy_house,
      o_movie_theater: o_movie_theater,
      o_discotheque: o_discotheque,
      answer: 1,
      brandAns: 1,
      brandAnsv2: 1,
      caT_01: true,
      caT_02: false,
      caT_03: false,
      caT_04: false,
      caT_05: false,
      caT_06: false,
      caT_07: false,
      delightfulshow: true,
      midDaymarvels: false,
      packages: [],
    };
  },
  mounted() {
    this.init();
    let recaptchaScript2 = document.createElement("script");
    recaptchaScript2.setAttribute("src", "./js/slick/slick.js");
    document.head.appendChild(recaptchaScript2);
    let recaptchaScript3 = document.createElement("script");
    recaptchaScript3.setAttribute("src", "./js/slick/slick.min.js");
    document.head.appendChild(recaptchaScript3);
    const recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute("src", "./js/script.js");
    document.head.appendChild(recaptchaScript);

    let question = document.querySelectorAll(".question");

    question.forEach((question) => {
      question.addEventListener("click", () => {
        const active = document.querySelector(".question.faqs_active");
        if (active && active !== question) {
          active.classList.toggle("faqs_active");
          active.nextElementSibling.style.maxHeight = 0;
        }
        question.classList.toggle("faqs_active");
        const answer = question.nextElementSibling;
        if (question.classList.contains("faqs_active")) {
          answer.style.maxHeight = answer.scrollHeight + "px";
        } else {
          answer.style.maxHeight = 0;
        }
      });
    });

    window.scrollTo({ top: 0, behavior: "smooth" });
  },
  methods: {
    async init() {
      await axios
        .get(process.env.VUE_APP_API_URL + "/packages", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((response) => {
          if (response.data.data) {
            const packages = [];
            _.forEach(_.reverse(response.data.data), function (item, index) {
              item.active = false;
              item.active_bottom = false;
              if (index == 0) {
                item.active = true;
                item.active_bottom = true;
              }
              if (index == 0) {
                item.package_image = "/img/packages_01.10472d17.jpg";
              }
              if (index == 1) {
                item.package_image = "/img/packages_02.d355a0c2.jpg";
              }
              packages.push(item);
            });

            this.packages = packages;
          }
        })
        .catch((error) => {
          this.$toast.error(error);
        });
    },
    selectPackage(item, index) {
      _.forEach(this.packages, function (item1, keyindex) {
        if (keyindex === index) {
          item1.active = true;
        } else {
          item1.active = false;
        }
      });
    },
    selectPackage2(item, index) {
      this.foodOpen.active_key = item.id + "_Breakfast";
      _.forEach(this.packages, function (item1, keyindex) {
        if (keyindex === index) {
          item1.active_bottom = true;
        } else {
          item1.active_bottom = false;
        }
      });
    },
    openCity(evt, cityName) {
      var i, tabcontent, tablinks;
      tabcontent = document.getElementsByClassName("tabcontent");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }
      tablinks = document.getElementsByClassName("tablinks");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(
          " week_tab_active",
          ""
        );
      }
      document.getElementById(cityName).style.display = "block";
      evt.currentTarget.className += " week_tab_active";
    },
    brand(data_id) {
      this.brandAns = data_id;
    },
    brandV1(data_id) {
      this.brandAnsv2 = data_id;
    },
    foods() {
      if (this.caT_01 == false) {
        this.caT_01 = true;
        this.caT_02 = false;
        this.caT_03 = false;
        this.caT_04 = false;
        this.caT_05 = false;
        this.caT_06 = false;
        this.caT_07 = false;
      }
    },
    sports() {
      if (this.caT_02 == false) {
        this.caT_02 = true;
        this.caT_01 = false;
        this.caT_03 = false;
        this.caT_04 = false;
        this.caT_05 = false;
        this.caT_06 = false;
        this.caT_07 = false;
      }
    },
    swimmingPool() {
      if (this.caT_03 == false) {
        this.caT_03 = true;
        this.caT_01 = false;
        this.caT_02 = false;
        this.caT_04 = false;
        this.caT_05 = false;
        this.caT_06 = false;
        this.caT_07 = false;
      }
    },
    childrenWP() {
      if (this.caT_04 == false) {
        this.caT_04 = true;
        this.caT_01 = false;
        this.caT_02 = false;
        this.caT_03 = false;
        this.caT_05 = false;
        this.caT_06 = false;
        this.caT_07 = false;
      }
    },
    childrenCH() {
      if (this.caT_05 == false) {
        this.caT_05 = true;
        this.caT_01 = false;
        this.caT_02 = false;
        this.caT_03 = false;
        this.caT_04 = false;
        this.caT_06 = false;
        this.caT_07 = false;
      }
    },
    movieTheater() {
      if (this.caT_06 == false) {
        this.caT_06 = true;
        this.caT_01 = false;
        this.caT_02 = false;
        this.caT_03 = false;
        this.caT_04 = false;
        this.caT_05 = false;
        this.caT_07 = false;
      }
    },
    discoTheque() {
      if (this.caT_07 == false) {
        this.caT_07 = true;
        this.caT_01 = false;
        this.caT_02 = false;
        this.caT_03 = false;
        this.caT_04 = false;
        this.caT_05 = false;
        this.caT_06 = false;
      }
    },

    delightfulDaycation() {
      if (this.delightfulshow == false) {
        this.delightfulshow = true;
        this.midDaymarvels = false;
      }
      // console.log("delightfulshow - " + this.delightfulshow);
    },
    midDayMarvelsbtn() {
      if (this.midDaymarvels == false) {
        this.midDaymarvels = true;
        this.delightfulshow = false;
      }
      // console.log("midDaymarvels - " + this.midDaymarvels);
    },
  },
};
</script>

<style scoped>
.question::after {
  content: unset;
  font-size: unset;
  position: unset;
  right: unset;
  transition: unset;
}

.package_tab {
  width: 100%;
}

.package_tab .body_heading {
  margin-top: 12px;
  font-size: 28px;
}

.package_tab .price_cal_title {
  font-size: 12px;
}

.villa_right_block p.body_heading {
  display: flex;
  margin-top: 12px;
  font-size: 36px;
}
.villa_right_block p.body_heading .price_cal_title {
  font-size: 12px;
  color: yellow;
  padding-top: 10px;
  padding-left: 5px;
}

.wedding_right_box .price_cal_box {
  justify-content: center;
}
.data_sub_heading {
  font-size: 24px;
}
.answercont {
  max-height: 0px;
  /* overflow: hidden; */
  transition: 0.3s !important;
  transform: scale(0);
  visibility: hidden;
}
.answercont.open {
  max-height: 1320px;
  transform: scale(1);
  visibility: visible;
}
.vill_btn {
  /* text-align: center; */
}

.wedding_right_box .site_button a {
  color: #fff !important;
  transition: 1s !important;
}

.wedding_right_box .site_button:hover a {
  color: #527422 !important;
}

.package_btn_block .site_button {
  transition: 1s !important;
}

.package_btn_block .site_button:hover a {
  color: #fff !important;
}

.data_sub_heading.price {
  display: flex;
}

.data_sub_heading.price .data_sub_heading {
  font-size: 12px;
  padding-left: 3px;
}

@media all and (max-width: 991px) {
  .tab_content_inner {
    width: 100%;
  }

  .price_cal_innr_box {
    width: 50%;
  }

  .long_l_leaf:before {
    display: none !important;
  }


}

/*  */

@media all and (min-width: 320px) and (max-width: 767px) {
}

@media all and (min-width: 768px) and (max-width: 980px) {
}

@media only screen and (min-width: 981px) and (max-width: 1024px) {
  /* picnic */
  img.slider_thumb_img,
  .slider_thumb_img_block {
    height: 102px;
    width: 102px;
  }

  /* end */

  /* Packages start */
  .lef_img:after {
    display: none;
  }

  .package_block {
    margin: auto 0px;
  }

  .price_main_white_box {
    padding: 18px;
  }

  .package_faqs_inner {
    /* margin: auto; */
    width: 20%;
  }

  /* Packages end  */
}

@media all and (min-width: 1025px) and (max-width: 1140px) {
  /* Packages start */
  .lef_img:after {
    display: none;
  }

  .package_block {
    margin: auto 0px;
  }

  .price_main_white_box {
    padding: 18px;
  }

  .package_faqs_inner {
    /* margin: auto; */
    width: 20%;
  }

  /* Packages end  */
}

@media all and (min-width: 1141px) and (max-width: 1280px) {
  /* Packages start */
  .lef_img:after {
    display: none;
  }

  .package_block {
    margin: auto 0px;
  }

  .price_main_white_box {
    padding: 18px;
  }

  .package_faqs_inner {
    /* margin: auto; */
    width: 20%;
  }

  /* Packages end  */
}

@media all and (min-width: 1281px) and (max-width: 1440px) {
  /* picnic */
  img.slider_thumb_img,
  .slider_thumb_img_block {
    height: 127px;
    width: 142px;
  }

  .lef_img:after {
    display: none;
  }

  /* Packages start */
  .package_block {
    margin: auto 0px;
  }

  .price_main_white_box {
    padding: 18px;
  }

  .package_faqs_inner {
    /* margin: auto; */
    width: 20%;
  }

  /* Packages end  */
  /* end */
}

@media all and (min-width: 1441px) and (max-width: 1680px) {
  /* picnic */
  img.slider_thumb_img,
  .slider_thumb_img_block {
    height: 127px;
    width: 142px;
  }

  /* welcome */
  .home_slider_container {
    height: 550px !important;
  }

  .lef_img:before {
    width: 120px;
    height: 120px;
    background-size: contain;
    top: 0 !important;
    right: 0px;
  }

  .lef_img:after {
    display: none;
  }

  /* end welcome */

  /* Packages start */
  .package_block {
    margin: auto 0px;
  }

  /* Packages end  */
  /* end */

  /*  */
}

@media all and (min-width: 1681px) and (max-width: 1880px) {
}
</style>
